import React from "react";
import { FirebaseApp } from "firebase/app";

interface IDBContext {
  app: FirebaseApp | undefined;
}
const initialState: IDBContext = {
  app: undefined
};

export const DatabaseContext = React.createContext(initialState);

export const useContext = () => React.useContext(DatabaseContext);
