import { useContext } from "../context/database";
import { FirebaseApp } from "firebase/app";
import { getFirestore, collection, getDocs, addDoc } from "firebase/firestore";
import { useState } from "react";

// interface IUser {
//   username: string;
//   pin: string;
// }
// interface ISecretSanta {
//   santa: string;
//   secret: string;
// }
export const useDatabase = () => {
  const app = useContext().app;
  const db = getFirestore(app);
  const [user, setUser] = useState(undefined);
  const [secret, setSecret] = useState(undefined);

  const getUsers = async () => {
    let usersCol = collection(db, "users");
    let users = await getDocs(usersCol);
    let uList = users.docs.map((i) => i.data());

    return uList;
  };
  const getSecret = async () => {
    let ssCol = collection(db, "secret_santa");
    let ss = await getDocs(ssCol);
    let uList = ss.docs.map((i) => i.data());

    return uList;
  };
  const login = async (username, pin) => {
    let uList = await getUsers();
    var user = uList.find((u) => u.username === username && u.pin === pin);
    if (!user) {
      return false;
    }
    setUser(user);
    return true;
  };

  const getMySecretSanta = async () => {
    let users = await getUsers();
    let secretSantas = await getSecret();

    let mySecret = secretSantas.find((i) => i.santa === user?.username);
    if (mySecret) {
      setSecret(mySecret);
      return;
    }
    var alreadyHas = secretSantas.map((i) => i.secret);
    let potentialUsers = users.filter(
      (w) => w.username !== user?.username && !alreadyHas.includes(w.username)
    );
    var myIndex = Math.floor(Math.random() * potentialUsers.length);
    var u = potentialUsers[myIndex];

    let coll = collection(db, "secret_santa");
    mySecret = {
      santa: user?.username || "",
      secret: u.username
    };
    addDoc(coll, mySecret);

    console.log(u);
    setSecret(mySecret);
  };

  return [login, getMySecretSanta, user, secret];
};
