import { TextField, Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDatabase } from "./hooks/useLogin";

const Login = () => {
  const [name, setName] = useState("");
  const [pin, setPin] = useState("");

  const [login, getMySecretSanta, user, secret] = useDatabase();

  useEffect(() => {
    if (user) {
      getMySecretSanta();
    }
  }, [user]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handlePinChange = (event) => {
    setPin(event.target.value);
  };
  const handleLoginClick = () => {
    login(name, pin);
  };
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" }
      }}
      noValidate
      autoComplete="off"
    >
      {!user && (
        <div>
          <TextField label="Nume" value={name} onChange={handleNameChange} />
          <TextField label="Pin" value={pin} onChange={handlePinChange} />
          <br />
          <Button variant="contained" onClick={handleLoginClick}>
            LOGIN
          </Button>
        </div>
      )}
      {secret && <h2>{secret.secret}</h2>}
    </Box>
  );
};

export { Login };
