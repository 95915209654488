import { initializeApp } from "firebase/app";
import * as database from "firebase/firestore";
import { Login } from "./Login";
import { DatabaseContext } from "./context/database";

import "./styles.css";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCp-dJn72zv-jsCR-G8ykchhS7dh0KNjP8",
  authDomain: "secret-santa-8ebb4.firebaseapp.com",
  projectId: "secret-santa-8ebb4",
  storageBucket: "secret-santa-8ebb4.appspot.com",
  messagingSenderId: "740133308801",
  appId: "1:740133308801:web:1fdc681e9607b96f898080"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default function App() {
  return (
    <DatabaseContext.Provider value={{ app: app }}>
      <div className="App">
        <h1>Secret Santa 2022</h1>
        <h2>;)</h2>
        <Login />
      </div>
    </DatabaseContext.Provider>
  );
}
